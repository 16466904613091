import ApiService from "@/core/services/api.service";

export default {

    // -----API DATA PERUSAHAAN------
    getPerusahaan(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/perusahaan", { params: credentials })
    },
    getPerusahaanAll(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/perusahaan/all", { params: credentials })
    },
    getPerusahaanSingle(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/perusahaan/single", { params: credentials })
    },
    getPerusahaanEdit(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/perusahaan/edit", { params: credentials })
    },
    addPerusahaan(credentials) {
        ApiService.setHeader();
        return ApiService.post("v2/master/perusahaan", credentials)
    },
    updatePerusahaan(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/master/perusahaan", credentials)
    },
    deletePerusahaan(credentials) {
        ApiService.setHeader();
        return ApiService.delete("v2/master/perusahaan", { data: credentials })
    },

    // -----API DATA FARM PERUSAHAAN------
    getPerusahaanFarm(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/perusahaan/farm", { params: credentials })
    },
    getPerusahaanFarmAll(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/perusahaan/farm/all", { params: credentials })
    },
    getPerusahaanFarmSingle(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/perusahaan/farm/single", { params: credentials })
    },
    getPerusahaanFarmEdit(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/perusahaan/farm/edit", { params: credentials })
    },
    addPerusahaanFarm(credentials) {
        ApiService.setHeader();
        return ApiService.post("v2/master/perusahaan/farm", credentials)
    },
    updatePerusahaanFarm(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/master/perusahaan/farm", credentials)
    },
    deletePerusahaanFarm(credentials) {
        ApiService.setHeader();
        return ApiService.delete("v2/master/perusahaan/farm", { data: credentials })
    },

    // -----API DATA HATCHERY PERUSAHAAN------
    getPerusahaanHatchery(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/perusahaan/hatchery", { params: credentials })
    },
    getPerusahaanHatcheryAll(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/perusahaan/hatchery/all", { params: credentials })
    },
    getPerusahaanHatcherySingle(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/perusahaan/hatchery/single", { params: credentials })
    },
    getPerusahaanHatcheryEdit(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/perusahaan/hatchery/edit", { params: credentials })
    },
    addPerusahaanHatchery(credentials) {
        ApiService.setHeader();
        return ApiService.post("v2/master/perusahaan/hatchery", credentials)
    },
    updatePerusahaanHatchery(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/master/perusahaan/hatchery", credentials)
    },
    deletePerusahaanHatchery(credentials) {
        ApiService.setHeader();
        return ApiService.delete("v2/master/perusahaan/hatchery", { data: credentials })
    },
}